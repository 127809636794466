<template>
  <!-- Category Section -->
  <div class="card h-100">
    <div class="card-body">
      <!-- Category Header -->
      <h5 class="custom-card-title mb-3">
        <i v-bind:class="icon"></i>
        <span>{{ title }}</span>
      </h5>
      <!-- Category Header -->

      <!-- Category List -->
      <b-tree-view
        v-if="ready"
        ref="tree"
        iconClassProp="icon"
        defaultIconClass="fas fa-folder"
        v-bind:data="filteredCategoryList"
        v-bind:expand-all="filterText.length > 1"
        v-bind:nodesDraggable="true"
        v-bind:contextMenu="true"
        v-bind:contextMenuItems="contextMenuItems"
        v-bind:renameNodeOnDblClick="false"
        v-bind:showIcons="true"
        v-bind:search-placeholder="placeholder"
        v-bind:selected-items="selectedCategories"
        v-on:nodeSelect="nodeSelect"
        v-on:contextMenuItemSelect="menuItemSelected"
        v-on:renameNode="onRename"
      ></b-tree-view>
      <div v-else style="min-height: 150px">
        <WeLoading />
      </div>
    </div>
    <!-- ./Category List -->
    <div
      class="card-footer pt-0 custom-scrollbar"
      style="max-height: 115px; overflow: auto"
      v-if="
        showSelectedCategories &&
        selectedCategories &&
        selectedCategories.length
      "
    >
      <div class="row align-items-center bg-light pt-2 border-bottom">
        <div class="col">
          <h5>
            {{ $t("selectedCategories") }} ({{ selectedCategories.length }})
          </h5>
        </div>
        <div class="col-auto">
          <span class="btn btn-link" v-on:click="removeNode()">{{
            $t("removeAll")
          }}</span>
        </div>
      </div>
      <span
        class="btn btn-sm btn-secondary m-2 font-14"
        v-for="node in selectedCategories"
        v-bind:key="node.id"
        v-on:click="removeNode(node.id)"
      >
        {{ node.name }} <i class="fas fa-times ml-2"></i>
      </span>
    </div>
  </div>
  <!-- ./Category Section -->
</template>

<script>
const bTreeView = () => import("./components/TreeView");
import { mapActions, mapState } from "vuex";

export default {
  name: "WeCategory",
  data() {
    return {
      contextMenuItems: [
        { code: "ADD_CHILD_NODE", label: this.$t("addSubCategory") },
        { code: "RENAME_NODE", label: this.$t("rename") },
      ],
      selectedNodes: [],
      filteredCategoryList: [],
      filterText: "",
      filterNodes: [],
      ready: false,
    };
  },
  components: {
    bTreeView,
  },
  methods: {
    ...mapActions("shared", ["getCategoryList", "createCategoryChildren"]),

    filter(e) {
      const result = [];

      if (this.filterText && this.filterText.length) {
        for (let i = 0; i < this.getItems.length; i++) {
          const item = this.getItems[i];

          if (
            item.name &&
            item.name
              .toLocaleLowerCase()
              .indexOf(this.filterText.toLocaleLowerCase()) >= 0
          ) {
            result.push(item);
          }
        }

        this.filteredCategoryList = result;
      } else {
        this.filteredCategoryList = this.getItems;
      }
    },
    nodeSelect(node, isSelected) {
      if (node.new) {
        return;
      }

      const data = {
        id: node.data.id,
        name: node.data.name,
        marketplaces: node.data.marketplaces || [],
        tax: node.data.tax || 0,
      };

      if (isSelected && !this.multiple) {
        this.removeNode();
      }

      let find = this.selectedCategories.find(({ id }) => id === data.id);
      if (isSelected && !find) {
        this.selectedCategories.push(data);
      }
    },
    removeNode(id) {
      if (id) {
        helper.arrayRemove(this.selectedCategories, "id", id);
      } else {
        this.selectedCategories.splice(0, this.selectedCategories.length);
      }
    },
    menuItemSelected(item, node) {
      if (item.code === "ADD_CHILD_NODE") {
        node.appendChild({
          id: helper.getRandomInteger(1, 999999),
          name: this.$t("newCategory"),
          new: true,
        });
      }
    },
    onRename(nodeData) {
      if (nodeData && nodeData.children) {
        for (let i = 0; i < nodeData.children.length; i++) {
          const child = nodeData.children[i];

          if (child.new) {
            const checkIndex = this.filterNodes.findIndex(
              (fn) => fn === child.id
            );
            if (checkIndex == -1) {
              const requestData = {
                id: child.id,
                category_id: nodeData.id, // Parent Category ID
                name: child.name,
                tax: nodeData.tax,
              };
              this.filterNodes.push(child.id); // Push Category ID in order to check uniqueness
              this.createNew(requestData);
            }
          } else if (child.children && child.children.length) {
            this.onRename(child);
          }
        }
      }
    },
    createNew(element) {
      this.createCategoryChildren({
        category_id: element.category_id, // Parent Category ID
        name: element.name,
        tax: element.tax,
        onSuccess: (result) => {
          if (result && result.data && result.data.status == "error") {
            this.$toast.error(this.$t("categoryCouldNotBeAdded"));
            this.removeFromList(element.id);
          } else {
            this.$toast.success(
              element.name + " " + this.$t("successfullyAdded")
            );
            this.createCategoryList();
          }
        },
      });
    },
    removeFromList(id) {
      helper.arrayRemove(this.getItems, "id", id);
      this.removeNode(id);
    },
    createCategoryList() {
      this.ready = false;
      this.getCategoryList({
        lang: this.language,
        onFinish: () => {
          this.ready = true;
          this.filteredCategoryList = this.getItems.map((item) => {
            return this.setSelectable(item);
          });
        },
      });
    },
    setSelectable(item, setDefault = null) {
      if (setDefault !== null) {
        item.selectable = setDefault;
      } else {
        item.selectable = item.id !== this.currentCategory;
      }

      if (item.id == this.currentCategory && item.depth === 0) {
        item.selectable = false;
        setDefault = false;
      }

      if (item.children && item.children.length) {
        item.children = item.children.map((child) =>
          this.setSelectable(child, setDefault)
        );
      }

      return item;
    },
  },
  computed: {
    ...mapState({
      category: (state) => state.shared.category,
    }),
    selectedCategories: {
      get() {
        return this.selected;
      },
      set(value) {
        this.selectedNodes = value;
      },
    },
    getItems: {
      get() {
        return this.category.categoryList;
      },
      set(value) {
        this.category.categoryList = value;
      },
    },
  },
  props: {
    draggable: {
      default: false,
    },
    multiple: {
      default: true,
    },
    selected: {
      default: () => [],
    },
    title: {
      default: "",
    },
    placeholder: {
      default: "",
    },
    icon: {
      default: "fas fa-stream",
    },
    items: {
      default: "",
    },
    currentCategory: {
      default: null,
    },
    showSelectedCategories: {
      default: true,
    },
    language: {
      default: "tr",
    },
    watchLanguage: {
      default: false,
    },
  },
  model: {
    prop: "selected",
  },
  mounted() {
    this.createCategoryList();
  },
  watch: {
    language: {
      handler: function (newVal, oldVal) {
        if (this.watchLanguage && newVal !== oldVal) {
          this.createCategoryList();
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.body-overflow {
  max-height: 350px;
  overflow: auto;
}
</style>
